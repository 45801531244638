.main {
  min-height: 100vh;
  min-height: 100dvh;
  min-height: 100svh;
  transition-property: padding;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  @apply flex w-full flex-col overflow-x-hidden;

  @media (min-width: 768px) {
    --main-layout-header-height: 6rem;
  }
}

.header {
  @apply sticky top-0 z-50 w-full border-b border-b-border;
}
